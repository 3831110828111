import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Nav, NavLink, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { getHelmetContent } from '../api/Api';
import DayRental from '../Home-Pages/Day-Rental';
import OneWay from '../Home-Pages/One-Way';
import RoundTrip from '../Home-Pages/Round-Trip';
import MyHelmet from '../Plugin/Helmet';

function Home() {
  const [activeTab, setActiveTab] = useState(1);
  const [getHomePageData, setHomePageData] = useState()
  const navigate = useNavigate()
  const navigateTo = () => navigate('/about-us');

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Home');
        setHomePageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      {getHomePageData &&
        <div>
          <MyHelmet
            title={getHomePageData.title}
            description={getHomePageData.description}
            canonicalUrl={getHomePageData.canonical}
          />
        </div>
      }
      <Container fluid>
        <>
          <Row className="position-relative">
            <Col lg={7} className='d-inline p-0'>
              <Image
                src='./assets/images/roadtrip.jpg'
                width='100%'
                style={{ height: '700px', objectFit: 'cover' }}
              />
            </Col>

            {/* Find Cab page section */}
            <Col className='home-page'>
              <Col lg={5} className='position-absolute'>
                <Col lg={8} className='mx-auto'>
                  <Card className='shadow-lg'>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Nav defaultActiveKey="1" className='d-flex align-item-center justify-content-between'>
                            <Nav.Item>
                              <Nav.Link eventKey="1" onClick={() => toggleTab(1)} style={{ borderBottom: activeTab === 1 ? '2px solid black' : 'none', }} className='text-dark'>ONE WAY</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="2" onClick={() => toggleTab(2)} style={{ borderBottom: activeTab === 2 ? '2px solid black' : 'none' }} className='text-dark'>ROUND TRIP</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="3" onClick={() => toggleTab(3)} style={{ borderBottom: activeTab === 3 ? '2px solid black' : 'none' }} className='text-dark'>DAY RENTAL</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                      {activeTab === 1 && <OneWay />}
                      {activeTab === 2 && <RoundTrip />}
                      {activeTab === 3 && <DayRental />}
                    </Card.Body>
                  </Card>
                </Col>
              </Col>
            </Col>
          </Row>
          <Row className="py-5">
            <Col data-aos="fade-right">
              <h2 className='text-center mb-3 heading-text'>ABOUT US</h2>
              <p className='ms-4 me-4'>we are India's leader for taxi travels provider. we are promise you safe ,reliable and cost-effective inter-city taxis all over Maharashtra and India. our mission is to simplify outstation travel for all Indians and we welcome your suggestions for improvement always.' Ococabs 'vision is to enable easier, safer and reliable outstation travel acro...
              </p>
              <Col className='text-center m-4 mx-auto' style={{ width: '110px' }}>
                <NavLink onClick={navigateTo} className='button text-white py-1' style={{ borderRadius: '50px' }}>Read More</NavLink>
              </Col>
            </Col>
            <Col className='mx-4'>
              <h2 className='color text-center heading-text'>WHY CHOOSE US ?</h2>
              <Col data-aos="fade-right">
                <h4><i className="fas fa-arrow-down me-3"></i>Less Price</h4>
                <p>We are customer oriented so we always look towards best fit price for their journey. We charge less amount which is reasonale for customers.</p>
              </Col>
              <Col data-aos="fade-right">
                <h4><i className="fa-solid fa-car me-3 mt-2"></i>Clean & Neat Cars</h4>
                <p>We highly maintain our cars inner atmoshphere. We are dedicated towards provide healthy environment inside cab/car.</p>
              </Col>
              <Col data-aos="fade-right">
                <h4><i className="fa-solid fa-person me-3 mt-2 border-bottom"></i>Well Trained Drivers</h4>
                <p>Our drivers and partners are well mannered and customer friendly in nature.</p>
              </Col>
            </Col>
          </Row>
        </>
      </Container>
    </div>
  )
}
export default Home;