import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getHelmetContent, getPageContent } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';
import Loader from '../Plugin/Loader';

function Termsandcondition() {
  const [getPageData, setPageData] = useState();
  const [getTermsPageData, setTermsPageData] = useState()
  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getPageContent('6');
        setPageData(pageData.data);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Terms & Conditions');
        setTermsPageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div>
        {getTermsPageData && (
          <>
            <MyHelmet
              title={getTermsPageData.title}
              description={getTermsPageData.description}
              canonicalUrl={getTermsPageData.canonical}
            />
          </>
        )}

        <Container className='py-4'>
          <h1 className='heading-text text-muted text-center py-4 fs-2'> TERMS AND CONDITIONS</h1>
          {getPageData ? (
            <p dangerouslySetInnerHTML={{ __html: getPageData }}></p>
          ) : (<Loader/>)}
        </Container>
      </div>
    </>
  )
}

export default Termsandcondition