import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { LuIndianRupee } from 'react-icons/lu';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { addCordinates, createBooking, getCouponData, varifyPayment } from "../api/Api";
import Update_Profile from '../Pages/Update_Profile';
import Loader from '../Plugin/Loader';

function Traveller() {
    const [carBookingData, setCarBookingData] = useState();
    const [getResponse, setResponse] = useState()
    const navigate = useNavigate();
    const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;
    const OCOCABS_LOGO = process.env.REACT_APP_OCOCABS_LOGO;
    const location = useLocation();
    const { selectedCar } = location.state || {};
    const [isChecked, setIsChecked] = useState(false);
    const [couponData, setcouponData] = useState()
    const [couponMessage, setCouponMessage] = useState()
    const [addCoupon, setAddCoupon] = useState({ coupon: "" })
    const [loading, setLoading] = useState(true);
    const [bookingError , setBookingError] = useState()

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const data = useSelector((state) => {
        return state.userDetail.data
    })

    const token = useSelector((state) => {
        return state.token.token
    })

    const myProfileUpdate = useSelector((state) => {
        return state.profileUpdate.data
    })

    const routeData = useSelector((state) => {
        return state.routeData.data
    })

    const nameAvailable = data?.name || myProfileUpdate?.name;

    const mobileNumb = useSelector((state) => {
        return state.mobile_no
    })

    useEffect(() => {
        const sendCoordinates = async () => {
            if (routeData?.source && routeData?.destination) {
                try {
                    const myFormData = new FormData();
                    myFormData.append('api', 'add-cordinates');
                    myFormData.append('latLong', routeData.source +' '+ routeData.destination);
                    myFormData.append('location',routeData.sourceAddress +' '+ routeData.destinationAddress);
                    await addCordinates(myFormData);
                } catch (error) {
                    console.error(`Error adding coordinates:`, error);
                }
            }
        };
        sendCoordinates();
    }, [routeData?.source , routeData?.destination]);

    const handleCreateBooking = async (e) => {
        const formData = new FormData();
        formData.append('api', 'create-booking');
        formData.append('token', token);
        formData.append('destination', routeData?.destination || '');
        formData.append('source', routeData?.source || '');
        formData.append('time', routeData?.time || '');
        formData.append('type', routeData?.type || '');
        formData.append('car_type', selectedCar?.id || '');
        formData.append('distance', selectedCar?.distance || '');
        formData.append('name', nameAvailable);
        formData.append('email', data?.email || myProfileUpdate?.email);
        formData.append('mobile_no', data?.mobile_no || mobileNumb?.data);
        formData.append('ret_time', routeData?.ret_time);
        formData.append('create_order', 1);

        try {
            const submittedForm = await createBooking(formData);
            if (submittedForm.data.status === 200) {
                setCarBookingData(submittedForm.data)
            }
            else {
                alert ('not found address')
                navigate('/')
            }
        } catch (error) {
            alert('Error creating booking:', error);
        }
    };

    useEffect(() => {
        if (selectedCar && selectedCar.id && nameAvailable) {
            handleCreateBooking();
        }else {
            setBookingError('Invalid Request Parameter !! Please try again ')
            navigate('/')
        }
    }, [selectedCar, nameAvailable]);

    const handlePaymentSuccess = async (response) => {
        setResponse(response.razorpay_payment_id)
        const varifyFormaData = new FormData();
        varifyFormaData.append('api', 'verify-payment');
        varifyFormaData.append('token', token);
        varifyFormaData.append('razorpay_id', response.razorpay_payment_id);
        varifyFormaData.append('payment_id', carBookingData?.data);
        varifyFormaData.append('status', carBookingData?.message);
        varifyFormaData.append('order_id', carBookingData?.order_id,);
        const amount = parseInt(carBookingData?.part_pay);
        if (couponData) {
            varifyFormaData.append('amount', carBookingData?.part_pay - couponData);
            varifyFormaData.append('code', couponData);
        } else {
            varifyFormaData.append('amount', amount);
        }

        if (carBookingData?.order_id && response.razorpay_payment_id) {
            try {
                const varify_payment = await varifyPayment(varifyFormaData);
                if (varify_payment) {
                    navigate('/my-booking-list')
                }
            } catch (error) {
                alert("An error occurred. Please try again.");
            }
        }
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        const options = {
            key: { RAZORPAY_KEY },
            currency: "INR",
            amount: carBookingData.part_pay * 100,
            name: "OcoCabs",
            receipt: carBookingData?.receipt,
            order_id: carBookingData?.order_id,
            description: carBookingData?.description,
            image: { OCOCABS_LOGO },
            handler: handlePaymentSuccess,
            prefill: {
                name: data?.name || myProfileUpdate?.name,
                email: data?.email || myProfileUpdate?.email,
                contact: data?.mobile_no || mobileNumb?.data,
            },
            notes: {
                shopping_order_id: carBookingData?.data,
            }
        }
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleChange = (e) => {
        setAddCoupon(prevValues => ({
            ...prevValues,
            [e.target.name]: e.target.value
        }));
    };

    const couponFormData = new FormData();
    couponFormData.append('api', 'check-coupon');
    couponFormData.append('token', token);
    couponFormData.append('code', addCoupon.coupon);
    const isEligibleForCoupon = carBookingData?.part_pay - couponData >= 20;

    const handleCouponSubmit = async (e) => {
        e.preventDefault();
        try {
            if (token) {
                const pageCouponData = await getCouponData(couponFormData);
                if (pageCouponData.status === 200) {
                    if(pageCouponData.data.data){
                        setAddCoupon({coupon:''})
                    }
                    setcouponData(pageCouponData.data.data);
                    setCouponMessage(pageCouponData.data.message);
                }
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <div className='my-5'>
            {bookingError && (<p className='text-danger'>{bookingError}</p>)}
            {loading ? (<div className='py-5'><Loader /></div>) : (getResponse ? (
                <>
                    {carBookingData && (
                        <Row className="booking-success">
                            <Col className='text-center'>
                                <i className="fa-solid fa-check icon-circle" ></i>
                                <h1 className='fs-4 text-success'>Congratulations !! </h1>
                                <h1 className='fs-4 text-success'>Your Booking Successfully Completed</h1>
                            </Col>
                        </Row>)
                    }
                </>) : (carBookingData &&
                    (
                        <Container className="traveller-page col-lg-3">
                            <Card className="shadow-lg ">
                                <Col className="text-center p-3">
                                    <p>Please Pay Advance to confirm your booking</p>
                                    {routeData?.type !== 'local' && (
                                        isEligibleForCoupon ? (
                                            <button
                                                className="px-4 button text-white"
                                                onClick={displayRazorpay}
                                            >
                                                <strong className="me-2">
                                                    <LuIndianRupee />
                                                    {carBookingData?.part_pay - couponData}
                                                </strong>
                                                PAY NOW
                                            </button>
                                        ) : (
                                            <button
                                                className="px-4 button text-white"
                                                onClick={displayRazorpay}
                                            >
                                                <strong className="me-2">
                                                    <LuIndianRupee />
                                                    {carBookingData.part_pay}
                                                </strong>
                                                PAY NOW
                                            </button>
                                        )
                                    )}
                                    {
                                        routeData?.type === "local" && (
                                            <button
                                                className="px-4 my-3 button text-white"
                                                onClick={displayRazorpay}
                                            >
                                                <strong className="me-2">
                                                    <LuIndianRupee />
                                                    {carBookingData.part_pay}
                                                </strong>
                                                PAY NOW
                                            </button>
                                        )
                                    }
                                </Col>
                                {
                                    routeData?.type !== 'local' && (
                                        <Col className="mb-2 mx-4">
                                            <div>
                                                <Form.Check
                                                    aria-label="option 1"
                                                    className="text-dark"
                                                    onChange={handleCheckboxChange}
                                                    label="Do you have a coupon?"
                                                />
                                            </div>
                                            {isChecked && (
                                                <>
                                                    <Form onSubmit={handleCouponSubmit}>
                                                        <div className="d-flex gap-2 my-2">
                                                            <Form.Group controlId="validationCustom01">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter your coupon"
                                                                    name="coupon"
                                                                    onChange={handleChange}
                                                                    value={addCoupon.coupon}
                                                                    className=' coupon border-success'
                                                                />
                                                            </Form.Group>
                                                            <button className='button text-white px-3' type='submit'>
                                                                APPLY
                                                            </button>
                                                        </div>
                                                        {couponMessage &&
                                                            <p className='text-danger'>{couponMessage}</p>
                                                        }
                                                        {couponData && !isEligibleForCoupon && (
                                                            <p className='text-danger'>This coupon is not valid for this route</p>
                                                        )}
                                                    </Form>
                                                </>
                                            )}
                                        </Col>
                                    )
                                }
                            </Card>
                        </Container>
                    )))}
            {
                !nameAvailable && (<Update_Profile />)
            }
        </div>
    );
}

export default Traveller;
