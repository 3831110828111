import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBlogs } from '../api/Api';
import Loader from '../Plugin/Loader';

function Blogs() {
  const [blogs, setBlogs] = useState('');
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBlogs();
        if (response.status === 200) {
          setBlogs(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <Container className='py-4'>
      <h1 className='heading-text text-center text-muted'>BLOGS</h1>
      {blogs.length > 0 ? (
        <Row>
          {blogs.map((blog) => (
            <Col lg={4} md={6} sm={12} key={blog.id}>
              <Link to={`/blog/${blog.slug}`} className='text-decoration-none'>
                <Card className='shadow-sm h-100'>
                  <Card.Img variant='top' src={blog.image_name} />
                  <Card.Body>
                    <Card.Title className='text-dark'>{blog.title}</Card.Title>
                  </Card.Body>
                  <Card.Footer>
                    <small className='text-muted'>
                      Published on {new Date(blog.date).toLocaleString('en-GB', {
                        dateStyle: 'long',
                        timeStyle: 'short'
                      })}
                    </small>
                  </Card.Footer>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      ):(<Loader/>) }
    </Container>
  );
}

export default Blogs;
