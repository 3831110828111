import 'bootstrap/dist/css/bootstrap.css';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './authentication_token/PrivatePage';
import My_booking_list from './Booking_Pages/My_booking_list';
import Search from './Booking_Pages/Search';
import Traveller from './Booking_Pages/Traveller';
import GoogleMapLoader from './Home-Pages/LoadScript';
import OutStation from './Home-Pages/OutStation';
import Footer from './Layouts/Footer';
import Header from './Layouts/Header';
import AboutUs from './Pages/About-Us';
import Blogs from './Pages/Blogs';
import BlogDetails from './Pages/Blogs_Details';
import CancelationPolicy from './Pages/Cancelation-Policy';
import ContactUs from './Pages/Contact-Us';
import Discount from './Pages/Discount';
import Home from './Pages/Home';
import Login from './Pages/Login';
import PrivacyPolicy from './Pages/Privacy-Policy';
import RefundPolicy from './Pages/Refund-Policy';
import Terms from './Pages/Terms';
import Termsandcondition from './Pages/Terms-and-condition';
import Update_Profile from './Pages/Update_Profile';
import './style.css';

function App() {
  const isLaptop = useMediaQuery({ minWidth: 1000 });
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1000 });
  const token = useSelector((state) => state.token.token);

  return (
    <div className='App'>
      <BrowserRouter>
        <div className='min-height'>
          <GoogleMapLoader>
            <Header />
            <Routes>
              {isLaptop && <Route path='/' element={<Home />} />}
              {isMobileOrTablet && <Route path='/' element={<OutStation />} />}
              <Route path='about-us' element={<AboutUs />} />
              <Route path='blogs' element={<Blogs />} />
              <Route path='login' element={<Login />} />
              <Route path='contact-us' element={<ContactUs />} />
              <Route path='terms-and-condition' element={<Termsandcondition />} />
              <Route path='privacy-policy' element={<PrivacyPolicy />} />
              <Route path='refund-policy' element={<RefundPolicy />} />
              <Route path='cancelation-policy' element={<CancelationPolicy />} />
              <Route path='discount' element={<Discount />} />
              <Route path="/blog/:slug" element={<BlogDetails />} />
              {token ? (
                <>
                  <Route path="update-profile" element={<Update_Profile />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="traveller" element={<Traveller />} />
                  <Route path="search" element={<Search />} />
                  <Route path="my-booking-list" element={<My_booking_list />} />
                </>
              ) : (
                <>
                  <Route path="update-profile" element={<PrivateRoute><Update_Profile /></PrivateRoute>} />
                  <Route path="terms" element={<PrivateRoute><Terms /></PrivateRoute>} />
                  <Route path="traveller" element={<PrivateRoute><Traveller /></PrivateRoute>} />
                  <Route path="search" element={<PrivateRoute><Search /></PrivateRoute>} />
                  <Route path="my-booking-list" element={<PrivateRoute><My_booking_list /></PrivateRoute>} />
                </>
              )}
            </Routes>
          </GoogleMapLoader>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
