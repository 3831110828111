import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getHelmetContent, getPageContent } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';
import Loader from '../Plugin/Loader';

function CancelationPolicy() {
  const [getPageData, setPageData] = useState();
  const [getCancellationPageData, setCancellationPageData] = useState()
  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getPageContent('8');
        setPageData(pageData.data);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Cancellation Policy');
        setCancellationPageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div>
        {getCancellationPageData && (
          <>
            <MyHelmet
              title={getCancellationPageData.title}
              description={getCancellationPageData.description}
              canonicalUrl={getCancellationPageData.canonical}
            />
          </>
        )}
        <Container className='py-4'>
          <h1 className='heading-text text-muted text-center py-4 fs-2'> CANCELATION POLICY</h1>
          {getPageData ? (
            <p dangerouslySetInnerHTML={{ __html: getPageData }}></p>
          ) : (<Loader/>)
          }
        </Container>
      </div>
    </>
  )
}

export default CancelationPolicy