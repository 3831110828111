import React, { useEffect, useState } from 'react';
import { Col, Container, Nav } from 'react-bootstrap';
import DayRental from '../Home-Pages/Day-Rental';
import OneWay from '../Home-Pages/One-Way';
import RoundTrip from '../Home-Pages/Round-Trip';
import MyHelmet from '../Plugin/Helmet';
import { getHelmetContent } from '../api/Api';

function OutStation() {
  const [activeTabs, setActiveTabs] = useState([3]);
  const [getHomePageData, setHomePageData] = useState();
  const [showAllTabs, setShowAllTabs] = useState(true);

  const toggleTab = (tab) => {
    if (tab === 1) {
      setActiveTabs([3]);
      setShowAllTabs(true);
    } else if (tab === 2) {
      setActiveTabs([2]);
      setShowAllTabs(false);
    }
    else if (tab === 4) {
      setActiveTabs([1, 4]);
      setShowAllTabs(true);
    }
    else if (tab === 3) {
      setActiveTabs([1, 3]);
      setShowAllTabs(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Home');
        setHomePageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      {getHomePageData && (
        <div>
          <MyHelmet
            title={getHomePageData.title}
            description={getHomePageData.description}
            canonicalUrl={getHomePageData.canonical}
          />
        </div>
      )}
      <Container fluid className="mx-auto">
        <Nav defaultActiveKey="1" className='Nav-Link'>
          <div className='tab-container m-2 gap-1'>
            {/* OUTSTATION Tab */}
            <Nav.Item>
              <Nav.Link
                eventKey="1"
                onClick={() => toggleTab(1)}
                className='outstation-tab text-white'
                style={{
                  backgroundColor: activeTabs.includes(3) || activeTabs.includes(4) ? '#cea427' : 'black',
                  width: '140px',
                  borderRadius: '5px',
                }}
              >
                OUTSTATION
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="2"
                onClick={() => toggleTab(2)}
                className='outstation-tab text-white'
                style={{
                  backgroundColor: activeTabs.includes(2) ? '#cea427' : 'black',
                  width: '140px',
                  borderRadius: '5px',
                }}
              >
                LOCAL
              </Nav.Link>
            </Nav.Item>
          </div>
          {showAllTabs && (
            <div className='tab-container mx-4'>
              {/* ONE-WAY Tab */}
              <Nav.Item>
                <Nav.Link
                  eventKey="3"
                  onClick={() => toggleTab(3)}
                  style={{
                    backgroundColor: activeTabs.includes(3) ? '#cea427' : 'black',
                    width: '110px',
                    borderRadius: '5px',
                  }}
                  className='outstation-tab text-white'
                >
                  ONE-WAY
                </Nav.Link>
              </Nav.Item>
              {/* ROUNDTRIP Tab */}
              <Nav.Item>
                <Nav.Link
                  eventKey="4"
                  onClick={() => toggleTab(4)}
                  style={{
                    backgroundColor: activeTabs.includes(4) ? '#cea427' : 'black',
                    width: '110px',
                    borderRadius: '5px',
                  }}
                  className='outstation-tab text-white'
                >
                  ROUNDTRIP
                </Nav.Link>
              </Nav.Item>
            </div>
          )}
        </Nav>
        <Col className='mx-auto'>
          {activeTabs.includes(3) && <OneWay />}
          {activeTabs.includes(2) && <DayRental />}
          {activeTabs.includes(4) && <RoundTrip />}
        </Col>
      </Container>
    </div>
  );
}

export default OutStation;
