import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { getHelmetContent, makeEnquiry } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';

function ContactUs() {
  const [formvalues, setformvalues] = useState({ name: '', mobile_no: '', email: '', type: '', subject: '', message: '' })
  const [pageData, setPageData] = useState();
  const [getContactPageData, setContactPageData] = useState()

  const handleChange = (e) => {
    setformvalues(prevValues => (
      { ...prevValues, [e.target.name]: e.target.value }
    ));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const contactEnquiryData = await makeEnquiry(formvalues);
      setPageData(contactEnquiryData.data.message)
      setformvalues({
        name: '',
        mobile_no: '',
        email: '',
        type: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Contact Us');
        setContactPageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className='py-4'>
      {getContactPageData && (
        <>
          <MyHelmet
            title={getContactPageData.title}
            description={getContactPageData.description}
            canonicalUrl={getContactPageData.canonical}
          />
        </>
      )}
      <Container>
        <h1 className='heading-text text-center text-muted fs-2'>CONTACT US</h1>
        <Row className='justify-content-center'>
          <Col lg={4} className='heading-text text-muted'>
            <h3 className='mt-3 mb-lg-5'>Keep in touch</h3>
            <h5><i className="fa-solid fa-location-dot"></i> Office Address</h5>
            <p className='ms-4'>
              <a href="https://www.google.com/maps?q=Sidhivinayak+Heights,+Narhe,+Pune,+Maharashtra+411041" target="_blank" rel="noopener noreferrer" className='nav-link'>
                S NO 23/2A Wing Sidhivinayak Heights,<br />
                Abhinav College, Narhe, Pune, <br />
                Maharashtra 411041
              </a>
            </p>
            <h5><i className="fa-solid fa-phone mt-lg-5 mt-2"></i> Lets Talk</h5>
            <a href="tel:+918421777463" className='nav-link ms-4'>+918421777463</a>
            <h5><i className="fa-solid fa-envelope  mt-lg-5 mt-3"></i> General Support</h5>
            <a href="mailto:info@ococabs.com" className='nav-link ms-4'>info@ococabs.com</a>
          </Col>
          <Col lg={5} className='mt-3 gap-3'>
            <p className='text-success fw-bold'>
              <span>{JSON.stringify(pageData)}</span>
            </p>
            <Form
              onSubmit={handleSubmit}
            >
              <Form.Group controlId="validationCustom01">
                <Form.Control
                  type="text"
                  placeholder='Enter Name'
                  required
                  className='mb-3'
                  name='name'
                  value={formvalues.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom02">
                <Form.Control
                  type="tel"
                  placeholder='Enter Mobile No.'
                  required
                  className='mb-3'
                  name='mobile_no'
                  value={formvalues.mobile_no}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom03">
                <Form.Control
                  type="email"
                  placeholder='Enter Email'
                  required
                  className='mb-3'
                  name='email'
                  value={formvalues.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom04">
                <Form.Control
                  type="text"
                  placeholder='Type'
                  required
                  className='mb-3'
                  name='type'
                  value={formvalues.type}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom05">
                <Form.Control
                  type="text"
                  placeholder='Subject'
                  required
                  className='mb-3'
                  name='subject'
                  value={formvalues.subject}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom06">
                <Form.Control
                  as="textarea"
                  type='text'
                  style={{ height: '100px' }}
                  required
                  placeholder='Enter your message/query'
                  name='message'
                  value={formvalues.message}
                  onChange={handleChange}
                  className='mb-3'
                />
              </Form.Group>
              <Col className='text-center mt-3'>
                <button className='button text-white py-1 px-2' type='submit' > Send Message </button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactUs