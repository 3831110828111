import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaLock, FaMobileAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { receiveotp, verifyOtp } from "../api/Api";
import Loader from "../Plugin/Loader";
import { setMobileNumb } from "../Redux_Toolkit/slices/LoginNumberSlice";
import { setToken } from "../Redux_Toolkit/slices/TokenSlice";

function Login_Page() {
    const routeData = useSelector((state) => {
        return state.routeData.data?.mobile_no
    })
    const [formValues, setFormValues] = useState({ mobile_no: routeData ?? '' });
    const [otp, setOtp] = useState(null);
    const [verifyData, setVerifyData] = useState({ mobile_no: routeData ?? '', otp: '', fcm: '' });
    const [timer, setTimer] = useState(30 * 60);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [myVerifyOtp, setVerifyOtp] = useState(false)
    const [error, setError] = useState(null);
    const [pageError, setPageError] = useState('');
    const [loading, setLoading] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const dispatch = useDispatch()
    const [isManualOtpRequest, setIsManualOtpRequest] = useState(false);
    const token = useSelector((state) => {
        return state.token.token
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));

        if (name === 'mobile_no') {
            setVerifyData(prevValues => ({
                ...prevValues,
                mobile_no: value,
            }));
        }
        if (name === "mobile_no") {
            setMobileError('');
        }
    };

    const handleVerifyChange = (e) => {
        const { name, value } = e.target;
        setError('')
        setVerifyData(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const validateMobileNumber = (mobile_no) => {
        const regex = /^\d{10}$/;
        return regex.test(mobile_no);
    };

    const onHandleOtp = async (e) => {
        if (e) e.preventDefault();
        setLoading(true);
        setIsManualOtpRequest(true);

        if (!validateMobileNumber(formValues.mobile_no)) {
            setMobileError('Mobile number must be exactly 10 digits.');
            return;
        }
        if (!token) {
            try {
                const submittedForm = await receiveotp(formValues);
                setFormValues(formValues)
                if (submittedForm.status === 200) {
                    setOtp(submittedForm.data);
                    setVerifyOtp(submittedForm.data)
                } else {
                    setPageError(submittedForm.message);
                }
            } catch (error) {
                setPageError('An error occurred. Please try again.');
            } finally {
                setLoading(false);
            }
            setTimeout(() => {
                setLoading(false);
                setOtp(true);
                setIsTimerActive(true);
                setTimer(30);
            }, 1000);
        }
    };

    useEffect(() => {
        if (!isManualOtpRequest && routeData) {
            onHandleOtp();
        }
        return () => setIsManualOtpRequest(false);
    }, [routeData]);

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const submittedForm = await verifyOtp(verifyData);
            if (submittedForm.data.status === 200) {
                dispatch(setMobileNumb(formValues.mobile_no))
                dispatch(setToken(submittedForm.data.data));
                setVerifyOtp(submittedForm.data)
            } else {
                setError(submittedForm.data.message);
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let countdown;
        if (isTimerActive && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerActive(false);
        }
        return () => clearInterval(countdown);
    }, [isTimerActive, timer]);

    const formatTime = (time) => {
        const limitedTime = Math.min(time, 30);
        const minutes = Math.floor(limitedTime / 60);
        const seconds = limitedTime % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const onResendOtp = () => {
        setOtp(false);
        setIsTimerActive(false);
        setTimer(30);
        onHandleOtp();
    };
    return (
        <Container className="py-5">
            <Row>
                {
                    !token ? (
                        <Col lg={5} className="mx-auto">
                            <Card className="shadow-lg py-3">
                                <Form className="p-4">
                                    <Col className="mx-lg-4">
                                    {myVerifyOtp?.data ? (<h1 className='fs-3 text-center text-success heading-text'>{myVerifyOtp?.data}</h1>):(<h1 className='fs-3 text-center text-muted heading-text'>LOGIN</h1>)
                                    }
                                        <Form.Group className="mb-1 pt-4 " controlId="mobile_no">
                                            <Col className="input-group d-flex">
                                                <span className="input-group-text">
                                                    <FaMobileAlt />
                                                </span>
                                                <Form.Control
                                                    type="tel"
                                                    placeholder="Enter mobile no"
                                                    value={formValues.mobile_no}
                                                    name="mobile_no"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Col>
                                            {mobileError && (
                                                <p className="text-danger mt-1">{mobileError}</p>
                                            )}
                                        </Form.Group>
                                        <Col className="text-center d-grid pt-4">
                                            {!otp && !token && (
                                                <button className="button text-white py-1 px-2" size="md" onClick={onHandleOtp} disabled={loading}>
                                                    {loading ? 'Loading...' : 'Get OTP'}
                                                </button>
                                            )}
                                        </Col>
                                        {otp && !token && (
                                            <>
                                                <Form.Group className="mb-3" controlId="otp">
                                                    <Col className="input-group">
                                                        <span className="input-group-text">
                                                            <FaLock />
                                                        </span>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter OTP"
                                                            name="otp"
                                                            value={verifyData.otp}
                                                            onChange={handleVerifyChange}
                                                            required
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Col className="text-end">
                                                    {otp && isTimerActive && (
                                                        <Col className="text-muted heading-text mt-3">
                                                            Resend OTP in {formatTime(timer)}
                                                        </Col>
                                                    )}
                                                    {!isTimerActive && otp && (
                                                        <button className="px-2 button text-white" size="md" onClick={onResendOtp}>
                                                            Resend OTP
                                                        </button>
                                                    )}
                                                </Col>
                                                <Col className="mt-3 d-grid text-center">
                                                    {error && <p className="text-danger">{error}</p>}
                                                    <button className="py-1 text-white button px-2" size="md" onClick={handleVerifyOtp} disabled={loading}>
                                                        {loading ? 'Verifying...' : 'Verify OTP'}
                                                    </button>
                                                </Col>
                                            </>
                                        )}
                                    </Col>
                                </Form>
                            </Card>
                        </Col>
                    ) : (<Loader />)
                }
            </Row>
        </Container>
    )
}
export default Login_Page;
