import { createSlice } from "@reduxjs/toolkit";

const profileUpdateSlice = createSlice({
    name:'profileUpdate',
    initialState:{
        data:null,
        loading:false,
        err : null
    },
    reducers:{
        setProfileUpdateData(state, action) {
            state.data = action.payload;
            state.loading = false;
        },
        setLoading(state) {
            state.loading = true;
        },
        setError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        clearProfileUpdate(state) {
            state.data = null;
        },
    }
})

export const { setProfileUpdateData, setLoading, setError, clearProfileUpdate } = profileUpdateSlice.actions;
export default profileUpdateSlice.reducer;