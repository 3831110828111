import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { MdCurrencyRupee } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Search() {
    const navigate = useNavigate();
    const [selectedCarId, setSelectedCarId] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const handleSelectCar = (carId) => {
        setSelectedCarId(carId);
    };

    const routeData = useSelector((state) => {
        return state.routeData?.data || []
    })

    const carsData = useSelector((state) => {
        return state.carsData.data
    })

    const handleSubmit = (carId) => {
        if (!carsData.length) {
            console.error('No route data available');
            return;
        }

        handleSelectCar(carId);
        const selectedCar = carsData.find(car => car.id === carId);
        if (selectedCar) {
            navigate('/terms', { state: { selectedCar } });
        } else {
            console.error('Car not found');
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container>
            {
                routeData && (
                    <>
                        <h1 className='fs-3 text-center mt-3 text-muted heading-text'>
                            {routeData?.type === 'one' && 'ONE WAY '}
                            {routeData?.type === 'return' && 'ROUND TRIP'}
                            {routeData?.type === 'local' && 'DAY RENTAL'}
                        </h1>
                        <div className='ms-1'>
                            <h6 >
                                <i className="fa-solid fa-circle-dot mt-2 me-2"></i>
                                <span className='text-muted'>{routeData?.sourceAddress ? routeData?.sourceAddress : routeData?.source}</span>
                            </h6>
                            <h6><i className="fa-solid fa-circle-dot me-2 text-success"></i>
                                <span className='text-muted'>{routeData?.type === 'local' ? (routeData?.name) : (routeData?.destinationAddress)}
                                </span>
                            </h6>
                            <h6>
                                <i className="far fa-calendar-alt me-2"></i>
                                <span className='text-muted'>{routeData?.time}</span>
                            </h6>
                            {
                                routeData?.ret_time && <h6><i className="far fa-calendar-alt mt-2 me-2"></i><span className='text-muted'>{routeData?.ret_time} </span></h6>
                            }
                        </div>
                    </>
                )
            }

            <Col className='d-lg-flex'>
                {carsData ? (
                    carsData.map((car) => (
                        <Card
                            className={`m-lg-1 my-3 ${selectedCarId === car.id ? 'border-none' : ''}`}
                            key={car.id}
                        >
                            <div key={car.id} className='cars_data '>
                                <Row className='car-container m-1 m-lg-3 gap-lg-2'>
                                    <Col lg={6} md={4} sm={4} className="text-center car_image_container border"
                                        style={{
                                            borderRadius: '5px',
                                            width: isMobile && '130px',
                                            height: isMobile && '130px'
                                        }}
                                    >
                                        <img src={car.image} alt={car.name} className='car_image' />
                                        <span className='discount-text text-white'>{car.discount}</span>
                                    </Col>
                                    <Col className='cars-text p-1'>
                                        <span className='text-muted text-center'>{car.name}  </span><br />
                                        <span className='text-dark'> {car.cars}</span><br />
                                        <span className='d-flex'>
                                            <span className=' text-danger font-weight-bold text-decoration-line-through'><MdCurrencyRupee />{car.oprice}</span>
                                            <span className='text-success font-weight-bold'><MdCurrencyRupee />{car.price}</span>
                                        </span>
                                        <span className='text-success'>Extra KM/fare:</span> {car.extra_km}<br />
                                        <span className='text-success'>include/KM:</span> {car.distance}
                                    </Col>
                                </Row>
                                <div className='d-grid m-1 m-lg-3'>
                                    <button
                                        className='button text-white '
                                        size="md"
                                        onClick={() => handleSubmit(car.id)}
                                    >  Book Cab
                                    </button>
                                </div>
                            </div>
                        </Card>
                    ))
                ) : (
                    <p>No available cars</p>
                )}
            </Col>
        </Container>
    );
}

export default Search;
