import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name:'profile',
    initialState:{
        data:null,
        loading:false,
        err : null
    },
    reducers:{
        setProfileData(state, action) {
            state.data = action.payload;
            state.loading = false;
        },
        setLoading(state) {
            state.loading = true;
        },
        setError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        clearProfile(state) {
            state.data = null;
        },
    }
})

export const { setProfileData, setLoading, setError, clearProfile } = profileSlice.actions;
export default profileSlice.reducer;