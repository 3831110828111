import { Col } from 'react-bootstrap';

export const SearchCabButton = () => {
    return (
        <Col className="d-grid my-4 my-lg-0">
            <button
                size="md"
                type='submit'
                className='text-white button py-1'
            >
                Search Cab
            </button>
        </Col>
    );
};