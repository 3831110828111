import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;

/*****
 * Used to add contact us page
 * @postData -> form data (name, email, phone, message, type , subject)
 */

export const makeEnquiry = async (postData) => {
    try {
        postData.api = 'create-enquiry'
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

/*****
* Used to get page content
* @postData -> form data ( api, page)
*/

export const getPageContent = async (page) => {
    try {
        const postData = {
            api: 'get-policies',
            page: page
        };
        const response = await axios.get(BASE_URL, {
            params: postData
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}

/* Used to send Otp
* @postData -> form data (api, mobile_no);
*/

export const receiveotp = async (postData) => {
    try {
        postData.api = 'send-otp'
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
* Used to verify Number
* @postData -> form data ( api, otp , mobile_no, fcm)
*/

export const verifyOtp = async (postData) => {
    try {
        postData.api = 'verify-login'
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to get rental package
 * @postData -> form data (api ,page)
 */

export const getRentalPackage = async (page) => {
    try {
        const postData = {
            api:'get-rental-package',
            page: page
        };
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response.data.data;
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to add get route details
 * @postData -> form data(api, token, destination, source, mobile_no, time , type , ret_time)
 */

export const getRouteDetails = async (postData) => {
    try {
        postData.api = 'get-route-details'
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to get notifications
 * @postData -> form data (api , token)
 */

export const getNotifications = async (api,token) => {
    try {
        const postData = {
            api:api,
            token: token
        };
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response.data.data);
    
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to upload profile photo
 * @postData -> form data (api , token )
 */

export const updateProfile = async (postData) => {
    try {
        postData.api ='update-profile'
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        return (response)
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to get profile data
 * @postData -> form data (api , token )
 */

export const getProfile = async (token) => {
    try {
        const postData = {
            api:'get-profile',
            token: token
        };
        const response = await axios.post(BASE_URL,postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        return (response.data.data)
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to create bookings
 * @postData -> form data (api , token , name , mobile_no, status , email , destination , source , type , time , car_type , distance ,  )
 */

export const createBooking = async ( postData) => {
    try {
        
        const response = await axios.post(BASE_URL, postData, {
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to get user booking
 * @postData -> form data (api , token ,type )
 */

export const getUserBooking = async (formData) => {
    try {
        const response = await axios.post(BASE_URL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to add create payment order
 * @postData -> form data (api , token , receipt , amount )
 */

export const paymentOrder = async (formData) => {
    try {
        const response = await axios.post(BASE_URL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to add verify Payment  
 * @postData -> form data (api , token )
 */

export const varifyPayment = async (formData) => {
    try {
        const response = await axios.post(BASE_URL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to cancel booking
 * @postData -> form data (api , token )
 */

export const cancelBooking = async (cancelFormData) => {
    try {
        const response = await axios.post(BASE_URL, cancelFormData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to add booking carrier
 * @postData -> form data (api , token )
 */

export const addBookingCarrier = async (formData) => {
    try {
        const response = await axios.post(BASE_URL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to upload profile
 * @postData -> form data (api , token , file )
 */

export const uploadProfilePhoto = async (formData) => {
    try {
        const response = await axios.post(BASE_URL,formData , {
            headers: {
            'Content-Type': 'multipart/form-data',
            }
        });
        return (response.data);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to add cordinates
 * @postData -> form data (api , token , file )
 */

export const addCordinates = async (formData) => {
    try {
        const response = await axios.post(BASE_URL,formData , {
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response.data);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to get page seo data
 * @postData -> form data (api , page )
 */

export const getHelmetContent = async (page) => {
    try {
        const postData = {
            api:'get-seo',
            page: page
        };
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
    // trigger error alert
    // something went wrong. Please check internet connection.
    }
}

/*****
 * Used to get driver details
 * @postData -> form data (api , token )
 */

export const getDriverDetails = async (formData) => {
    try {
        const response = await axios.post(BASE_URL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/*****
 * Used to get blogs
 * @postData -> form data (api)
 */

export const getBlogs = async (page) => {
    try {
        const postData = {
            api:'get-blogs',
        };
        const response = await axios.post(BASE_URL, postData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}

/* Used to get blogs
* @postData -> form data (api,slug)
*/

export const getBlogsDetails = async (formData) => {
   try {
       const response = await axios.post(BASE_URL, formData, {
           headers: {
             'Content-Type': 'application/x-www-form-urlencoded'
           }
       });
       return (response);
   } catch (error) {
       console.log(error);
   }
}

/* Used to get Coupon Data
* @postData -> form data (api,token, code)
*/

export const getCouponData = async (formData) => {
    try {
        const response = await axios.post(BASE_URL, formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return (response);
    } catch (error) {
        console.log(error);
    }
}