import { createSlice } from "@reduxjs/toolkit";

const mobileSlice = createSlice({
    name:'mobile',
    initialState:{
        data: null,
        loading: false,
    },
    reducers:{
        setMobileNumb(state, action) {
            state.data = action.payload;
            state.loading = false;
        },
        clearMobile(state) {
            state.data = null;
        },
    }
})

export const { setMobileNumb, clearMobile } = mobileSlice.actions;
export default mobileSlice.reducer;