import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getHelmetContent } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';
import Loader from '../Plugin/Loader';
import Login_Page from './Login_Page';
import Update_Profile from './Update_Profile';

function Login() {
  const navigate = useNavigate();
  const [getLoginPageData, setLoginPageData] = useState()

  const token = useSelector((state) => {
    return state.token.token
  })

  const routeData = useSelector((state) => {
    return state.routeData.data
  })

  const carsData = useSelector((state) => {
    return state.carsData.data
  })

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Login');
        setLoginPageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (token) {
      const timer = setTimeout(() => {
        if (routeData && carsData) {
          navigate('/search')
        }
        else {
          navigate('/');
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [token, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const data = useSelector((state) => {
    return state.userDetail.data
  })

  const myProfileUpdate = useSelector((state) => {
    return state.profileUpdate.data
  })

  const [loading, setLoading] = useState(true);
  const noNameAvailable = !data?.name || !myProfileUpdate?.name;

  return (
    <div>
      {getLoginPageData && (
        <>
          <MyHelmet
            title={getLoginPageData.title}
            description={getLoginPageData.description}
            canonicalUrl={getLoginPageData.canonical}
          />
        </>
      )}
      <Container>
        <Login_Page />
      </Container>
      {
        noNameAvailable && token && ((
          <Container className="my-auto">
            {loading ? (
              <p className='mt-4' ><Loader /></p>
            ) : (
              noNameAvailable && token && (
                <Update_Profile />
              )
            )}
          </Container>
        ))
      }
    </div>
  )
}

export default Login