import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { getPageContent } from '../api/Api';
import Loader from "../Plugin/Loader";
function Terms() {
    const location = useLocation();
    const {selectedCar} = location.state || {};
    const navigate = useNavigate()
    const [getOtherTerms, setOtherTerms] = useState();

    const handleCancel = (() => {
        navigate('/')
    })

    const handleProceed = (() => {
        navigate('/traveller', {
            state: {selectedCar},
        })
    })

    useEffect(() => {
        async function fetchData() {
            try {
                const pageData = await getPageContent('12');
                setOtherTerms(pageData.data);
            } catch (error) {
                console.error('Error fetching state data:', error);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Container className='text-muted py-5'>
                {getOtherTerms ? (
                    <>
                        <p dangerouslySetInnerHTML={{ __html: getOtherTerms }}></p>
                        <div className='d-flex gap-3 justify-content-center'>
                            <button onClick={handleCancel} className="button text-white px-2">Cancel</button>
                            <button onClick={handleProceed} className="button text-white px-3">Proceed</button>
                        </div>
                    </>
                ) :(<Loader/>)
              }
            </Container>
        </>
    )
}

export default Terms