import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getHelmetContent, getPageContent } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';
import Loader from '../Plugin/Loader';

function PrivacyPolicy() {
  const [getPageData, setPageData] = useState();
  const [getPrivacyPolicyPageData, setPrivacyPolicyPageData] = useState()

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getPageContent('5');
        setPageData(pageData.data);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('privacy-policy');
        setPrivacyPolicyPageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      {getPrivacyPolicyPageData && (
        <>
          <MyHelmet
            title={getPrivacyPolicyPageData.title}
            description={getPrivacyPolicyPageData.description}
            canonicalUrl={getPrivacyPolicyPageData.canonical}
          />
        </>
      )}
      <Container className='py-4'>
        <h1 className='heading-text text-muted text-center py-4 fs-2'> PRIVACY POLICY </h1>
        {getPageData ? <p dangerouslySetInnerHTML={{ __html: getPageData }}></p> : (<Loader />)
        }
      </Container>
    </div>
  )
}
export default PrivacyPolicy