import { createSlice } from "@reduxjs/toolkit";

const carsDataSlice = createSlice({
    name:'carsData',
    initialState:{
        data:null,
        loading:false,
        err : null,
    },
    reducers:{
        setCarsData(state, action) {
            state.data = action.payload;
            state.loading = false;
        },
        setLoading(state) {
            state.loading = true;
        },
        setError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        clearCarsData(state) {
            state.data = null;
        },
    }
})

export const { setCarsData, setLoading, setError, clearCarsData } = carsDataSlice.actions;
export default carsDataSlice.reducer;