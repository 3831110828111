import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import profileReducer from '../Redux_Toolkit/slices/userDetailsSlice';
import carsDataReducer from './slices/CarsDataSlice';
import mobileReducer from './slices/LoginNumberSlice';
import routeDataReducer from './slices/RouteDataSlice';
import tokenReducer from './slices/TokenSlice';
import profileUpdateReducer from './slices/userUpdate';

const persistConfig = {
  key: 'userDetail',
  storage,
};

const rootReducer = combineReducers({
  userDetail: profileReducer,
  token: tokenReducer,
  mobile_no: mobileReducer,
  profileUpdate : profileUpdateReducer,
  routeData : routeDataReducer,
  carsData : carsDataReducer
});

const persistedUserDetailReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer:  persistedUserDetailReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
