import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Container, Modal, Row } from 'react-bootstrap';
import { FaUser } from "react-icons/fa";
import { MdCall, MdOutlineCurrencyRupee } from "react-icons/md";
import { useSelector } from 'react-redux';
import { getUserBooking } from '../api/Api';

function My_Bookings() {
    const [bookings, setBookings] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const doc = new jsPDF();
    const componentRef = useRef();
    const token = useSelector((state) => {
        return state.token.token
    })
    const handleGenerateAllPDFs = () => {
        const input = componentRef.current;
        const isMobile = window.innerWidth <= 768;
        const pageWidth = isMobile ? 180 : 210;
        const pageHeight = 297;
        const margin = 10;
        const applyFontSize = (element, fontSize) => {
            element.style.fontSize = fontSize;
            Array.from(element.children).forEach(child => applyFontSize(child, fontSize));
        };
        applyFontSize(input, isMobile ? '10px' : '17px');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = pageWidth - 2 * margin;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const doc = new jsPDF('portrait', 'mm', 'a4');
            if (isMobile) {
                const scale = Math.min((pageHeight - 2 * margin) / imgHeight, (pageWidth - 2 * margin) / imgWidth, 1);
                const scaledImgWidth = imgWidth * scale;
                const scaledImgHeight = imgHeight * scale;
                const xOffset = margin
                const yOffset = (pageHeight - scaledImgHeight) / 2;
                doc.addImage(imgData, 'PNG', xOffset, yOffset, scaledImgWidth, scaledImgHeight);
            } else {
                const imageHeightPerPage = pageHeight - 2 * margin;
                let yOffset = 0;
                while (yOffset < imgHeight) {
                    doc.addImage(imgData, 'PNG', margin, -yOffset + margin, imgWidth, imgHeight);
                    if (yOffset + imageHeightPerPage < imgHeight) {
                        doc.addPage();
                    }
                    yOffset += imageHeightPerPage;
                }
            }
            doc.save(`Booking_Details_${selectedBooking.booking_id}.pdf`);
        });
    };

    const handleShowModal = (booking) => {
        setSelectedBooking(booking);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedBooking(null);
        setShowModal(false);
    };

    useEffect(() => {
        const formData = new FormData();
        formData.append('api', 'get-user-booking');
        formData.append('token', token);
        async function fetchData() {
            if (token) {
                try {
                    const bookingDetails = await getUserBooking(formData);
                    if (bookingDetails) {
                        setBookings(bookingDetails.data.data);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        fetchData();
    }, [token]);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'confirmed':
                return 'text-success';
            case 'pending':
                return 'text-warning';
            case 'cancelled':
                return 'text-danger';
            default:
                return 'text-secondary';
        }
    };

    return (
        <Container fluid className='py-4 my-bookings'>
            <h1 className='heading-text text-center text-muted mt-4 fs-2'>MY BOOKINGS</h1>
            {bookings ? (
                <div>
                    {bookings.map((booking, index) => (
                        <Row key={index} className='my-3'>
                            <Col lg={6} className='mx-auto'>
                                <Card className='shadow-sm rounded-lg'>
                                    <CardBody>
                                        <Row>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h5 className={`fw-bold ${getStatusColor(booking.status)}`}>
                                                    Booking Status: <span>{booking.status}</span>
                                                </h5>
                                                <div className="text-end">
                                                    <button onClick={() => handleShowModal(booking)} className='button text-white px-2'>
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='my-1'>
                                                <h5>Dear <span>{booking.traveller_name}</span>, your booking ID is <strong className='text-success'>{booking.booking_id}</strong></h5>
                                            </div>
                                        </Row>
                                        <Card className='booking-details-card'>
                                            <Col className='border-right p-3'>
                                                <h6 className='border-bottom pb-2'>BOOKING DETAILS</h6>
                                                <p className='mt-3'><i className="fa-solid fa-map-marker-alt text-secondary me-2"></i><strong>Pick up Location:</strong> {booking.source}</p>
                                                <p className='mt-3'><i className="fa-solid fa-map-marker-alt text-primary me-2"></i><strong>Drop Location:</strong> {booking.destination}</p>
                                                <p><strong><i className="fa-solid fa-road me-2"></i>Distance:</strong> <span className='text-dark'>{booking.km}</span></p>
                                            </Col>
                                            <Row className='p-3 d-flex'>
                                                <Col lg={6}>
                                                    <h6 className='border-bottom pb-2'>TRAVELLER DETAILS</h6>
                                                    <p><FaUser className='me-2' /> {booking.traveller_name}</p>
                                                    <p><MdCall className='me-2' /> {booking.traveller_mobile}</p>
                                                </Col>
                                                <Col lg={6}>
                                                    {!booking.ret_date ? (<h6 className='border-bottom pb-2'>JOURNEY DATE TIME</h6>)
                                                        : (<h6 className='border-bottom pb-2'>JOURNEY DATE TIME & RETURN DATE TIME</h6>)
                                                    }
                                                    <p><strong><i className="fa-solid fa-calendar-alt me-2"></i>From Date:</strong> <span className='text-dark'>{booking.date}</span></p>
                                                    {booking.ret_date && (
                                                        <p><strong><i className="fa-solid fa-calendar-alt me-2"></i>To Date:</strong> <span className='text-dark'>{booking.ret_date}</span></p>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                </div>
            ) : (
                <p className='text-center mt-1 text-muted'>No Bookings Found</p>
            )}
            {/* Modal to show detailed booking information */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Booking Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedBooking && (
                        <Col className='py-lg-4 my-bill' >
                            {/* Logo and Company Name */}
                            <Col className='mx-3' ref={componentRef}>
                                <Row className='text-center'>
                                    <Col>
                                        <img src="./assets/images/Ococabs_logo/logo.png" alt="Ococabs" style={{ width: '100px' }} />
                                        <p>S NO 23/2A Wing Sidhivinayak Hights,<br />
                                            Abhinav Collage, Narhe, Pune, <br />
                                            Maharashtra 411041<br />
                                            Phone: +91-8421777463</p>
                                    </Col>
                                </Row>
                                {/* Bill Header */}
                                <div className='d-flex px-lg-2'>
                                    <div>
                                        <h5 className={`fw-bold ${getStatusColor(selectedBooking.status)}`}>
                                            Booking Status: <span>{selectedBooking.status}</span>
                                        </h5>
                                        <h6>Booking ID: <span className='text-success'>{selectedBooking.booking_id}</span></h6>
                                        <h6>Dear <span>{selectedBooking.traveller_name}</span>,</h6>
                                    </div>
                                </div>
                                {/* Booking and Traveller Details */}
                                <Row className='px-lg-2 pt-lg-1 d-flex'>
                                    <Col lg={8} md={12} sm={12}>
                                        <h6>Booking Details</h6>
                                        <p><strong>Pick-up Location:</strong> {selectedBooking.source}<br />
                                            <strong>Drop Location:</strong> {selectedBooking.destination}<br />
                                            <strong>Distance:</strong> {selectedBooking.km}</p>
                                    </Col>
                                    <Col lg={4} md={12} sm={12}>
                                        <h6>Travelling Date & Time</h6>
                                        <p><strong>From Date:</strong> {selectedBooking.date}</p>
                                        {selectedBooking.ret_date && (
                                            <p><strong>Return Date:</strong> {selectedBooking.ret_date}</p>
                                        )}
                                    </Col>
                                </Row>
                                {/* Journey Details */}
                                <Row className='px-lg-2 travelling-detail d-flex'>
                                    <Col>
                                        <h6>Traveller Details</h6>
                                        <p>
                                            <FaUser className='me-lg-2' /> {selectedBooking.traveller_name} <br />
                                            <MdCall className='me-lg-2' /> {selectedBooking.traveller_mobile}
                                        </p>
                                        {/* <p><MdAttachEmail className='me-2' /> {selectedBooking.traveller_email}</p> */}
                                    </Col>

                                    <Col>
                                        <h6>Car Details</h6>
                                        <p>
                                            <strong>Car Type:</strong> {selectedBooking.car_type} <br />
                                            {selectedBooking.status === "Confirmed" && (
                                                <>
                                                    <strong>Car Model:</strong> {selectedBooking.car_model} <br />
                                                    <strong>Car Number:</strong> {selectedBooking.car_number} <br />
                                                </>
                                            )}
                                            <strong>Type:</strong> {selectedBooking.type}
                                        </p>
                                    </Col>
                                </Row>

                                {/* Payment Details */}
                                <Row className='px-lg-2'>
                                    <Col>
                                        <h6>Payment Details</h6>
                                        {selectedBooking.extra && (
                                            <div className='d-flex justify-content-between'>
                                                <p><strong>Extra Charges:</strong></p>
                                                <p className='text-dark'>{selectedBooking.extra}<MdOutlineCurrencyRupee /></p>
                                            </div>
                                        )}
                                        <div className='d-flex justify-content-between'>
                                            <p><strong>Online Paid Amount:</strong></p>
                                            <p className='text-dark'>- {selectedBooking.paid_amount}<MdOutlineCurrencyRupee /></p>
                                        </div>
                                        {selectedBooking.status === 'Confirmed' && (
                                            <div className='d-flex justify-content-between'>
                                                <p><strong>Remaining Amount:</strong>
                                                    {selectedBooking.amount - selectedBooking.paid_amount}<MdOutlineCurrencyRupee /></p>
                                            </div>
                                        )}
                                        <div className='d-flex justify-content-between border-top border-2'>
                                            <p><strong>Total Amount:</strong></p>
                                            <p className='text-dark'>{selectedBooking.amount}<MdOutlineCurrencyRupee /></p>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Driver Details */}
                                {selectedBooking.status === "Confirmed" && (
                                    <Row className='px-lg-2'>
                                        <Col lg={8}>
                                            <h6>Driver Details :</h6>
                                            <p><FaUser className='me-lg-2' /> {selectedBooking.driver_name}</p>
                                            <p><MdCall className='me-lg-2' /> {selectedBooking.driver_mobile}</p>
                                        </Col>
                                        <Col lg={4} className='text-lg-end'>
                                            <img
                                                src={selectedBooking.driver_image}
                                                alt="Driver"
                                                style={{ width: '120px', height: '120px', objectFit: 'cover', borderRadius: '5%' }}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {selectedBooking.details && (
                                    <Row className='px-lg-2'>
                                        <Col>
                                            <p><strong>Details:</strong> {selectedBooking.details}</p>
                                        </Col>
                                    </Row>
                                )}
                                {selectedBooking.note && (
                                    <Row className='px-lg-2'>
                                        <Col>
                                            <p><strong>Note:</strong> {selectedBooking.note}</p>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            {selectedBooking.status === 'Completed' ? (
                                <Col className="text-center my-2 mb-1">
                                    <button className="button p-2 text-white" onClick={handleGenerateAllPDFs}>Download Bill</button>
                                </Col>
                            ) : (
                                <CardFooter className='text-center my-lg-2'>
                                    <Col className="text-center ">
                                        <p className='text-danger fw-bold'>Note: Please contact us to cancel the booking.</p>
                                        <button className='button p-2 text-white' onClick={() => window.location.href = 'tel:+918421777463'} >
                                            Cancel Booking
                                        </button>
                                    </Col>
                                </CardFooter>
                            )}
                        </Col>
                    )}
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default My_Bookings;
