import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getBlogsDetails } from '../api/Api';
import Loader from '../Plugin/Loader';

function BlogDetails() {
    const { slug } = useParams();
    const [blog, setBlog] = useState('');

    useEffect(() => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('api', 'get-blog-details');
            formData.append('slug', slug);
            try {
                const response = await getBlogsDetails(formData);
                if (response.status === 200) {
                    setBlog(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching blog details:', error);
            }
        }
        fetchData();
    }, []);

    return (
        <Container className='my-4'>
            <Row>
                <Col md={8} className='mx-auto'>
                    {blog.images && blog.images.length > 0 ? (
                        <>
                            <Card className='shadow-sm'>
                                <Card.Body>
                                    <Card.Title className='text-center fs-1'>{blog.title}</Card.Title>
                                    <Carousel className='mb-4'>
                                        {blog.images.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <img
                                                    className='d-block w-100'
                                                    src={image}
                                                    alt={`Slide ${index}`}
                                                    style={{ objectFit: 'cover', maxHeight: '400px' }}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <div
                                        className='fs-5  blog-details-content'
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.details) }}
                                    />
                                    <div className='mt-4'>
                                        <p>
                                            <strong>Published on:</strong>{' '}
                                            {new Date(blog.date).toLocaleString('en-GB', {
                                                dateStyle: 'long',
                                                timeStyle: 'short',
                                            })}
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </>
                    ) : (<Loader />)}
                </Col>
            </Row>
        </Container>
    );
}

export default BlogDetails;
