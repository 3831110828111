// GoogleMapLoader.js
import { LoadScript } from '@react-google-maps/api';
const MAP_KEY = process.env.REACT_APP_MAP_KEY;
const libraries = ['places','geometry'];

const GoogleMapLoader = ({ children }) => {
  return (
    <LoadScript googleMapsApiKey={MAP_KEY} libraries={libraries}>
      {children}
    </LoadScript>
  );
};

export default GoogleMapLoader;
