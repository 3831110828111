import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getHelmetContent, getPageContent } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';
import Loader from '../Plugin/Loader';

function RefundPolicy() {
  const [getPageData, setPageData] = useState();
  const [getRefundPageData, setRefundPageData] = useState()

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getPageContent('7');
        setPageData(pageData.data);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('Refund Policy');
        setRefundPageData(pageData);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div>
        {getRefundPageData && (
          <>
            <MyHelmet
              title={getRefundPageData.title}
              description={getRefundPageData.description}
              canonicalUrl={getRefundPageData.canonical}
            />
          </>
        )}
          <Container className='py-4'>
            <h1 className='heading-text text-muted text-center py-4 fs-2'>REFUND POLICY</h1>
            {getPageData ? <p dangerouslySetInnerHTML={{ __html: getPageData }}></p> : (<Loader/>)
            }
          </Container>
      </div>
    </>
  )
}
export default RefundPolicy