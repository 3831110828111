import { Autocomplete } from "@react-google-maps/api";
import { endOfDay, format, setHours, setMinutes, startOfDay } from 'date-fns';
import { useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { CiUser } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getRouteDetails } from '../api/Api';
import { SearchCabButton } from "../button/button";
import { setCarsData } from "../Redux_Toolkit/slices/CarsDataSlice";
import { setMobileNumb } from "../Redux_Toolkit/slices/LoginNumberSlice";
import { setRouteData } from "../Redux_Toolkit/slices/RouteDataSlice";

function RoundTrip() {
    const mobileNumb = useSelector((state) => {
        return state.mobile_no
    })
    const [returnDate, setReturnDate] = useState(() => {
        const now = new Date();
        now.setHours(22, 0, 0, 0);
        return now;
    });
    const formattedReturnDate = format(returnDate, 'dd-MM-yyyy hh:mm a');
    const initialvalues = { destination: "", source: "", type: "return", time: "", ret_time: formattedReturnDate ?? "", mobile_no: mobileNumb?.data ?? '' }
    const [formData, setFormData] = useState(initialvalues)
    const destinationRef = useRef(null);
    const sourceRef = useRef(null);
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(new Date());
    const dispatch = useDispatch()
    const [error, setError] = useState()
    const [locationError, setLocationError] = useState('')

    const token = useSelector((state) => {
        return state.token.token
    })
    const handlePlaceChange = async (place, name) => {
        if (place && place.geometry && place.geometry.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const locationString = `${lat},${lng}`;
            const fullAddress = place.formatted_address || '';
            setLocationError('');
            setFormData(prevValues => ({
                ...prevValues,
                [`${name}`]: locationString,
                [`${name}Address`]: fullAddress
            }));
        } else {
            setLocationError(`No valid location selected for ${name}. Please select a valid place.`);
        }
    };

    const handledestinationInputChange = (e) => {
        setFormData((prevValues) => ({
            ...prevValues,
            destinationAddress: e.target.value
        }));
    };

    const handlesourceInputChange = (e) => {
        setFormData((prevValues) => ({
            ...prevValues,
            sourceAddress: e.target.value,
        }));
    };

    const handleChange = (e) => {
        setFormData(prevValues => ({ ...prevValues, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const submittedForm = await getRouteDetails(formData);
            if (submittedForm.data && submittedForm.data.data) {
                const myCarData = submittedForm.data.data;
                dispatch(setMobileNumb(formData.mobile_no))
                dispatch(setCarsData(myCarData))
                dispatch(setRouteData(formData))
                navigate('/search');
            } else {
                setError(submittedForm.data.message)
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleTimeChange = (date, timeType) => {
        const formattedDate = format(date, 'dd-MM-yyyy hh:mm a');
        if (timeType === 'time') {
            setStartDate(date);
        } else if (timeType === 'ret_time') {
            setReturnDate(date);
        }
        setFormData(prevValues => ({
            ...prevValues,
            [timeType]: formattedDate
        }));
    };

    const getMinTime = (date) => {
        const currentDate = new Date();
        if (date.toDateString() === currentDate.toDateString()) {
            return setHours(
                setMinutes(new Date(), Math.ceil(currentDate.getMinutes() / 15) * 15),
                currentDate.getHours()
            );
        }
        return startOfDay(date);
    };

    const getMaxTime = (date) => {
        return endOfDay(date);
    };

    return (
        <Form className='mt-4' onSubmit={handleSubmit}>
            <Col>
                <Form.Group controlId="formGridPhoneNo">
                    <Autocomplete
                        onLoad={ref => (sourceRef.current = ref)}
                        onPlaceChanged={() => handlePlaceChange(sourceRef.current.getPlace(), 'source')}
                        options={{
                            componentRestrictions: { country: 'in' },
                            types: ['address'],
                        }}
                    >
                        <div className='d-flex'>
                            <div>
                                <span>
                                    <i className="fa-solid fa-circle-dot me-3"></i>
                                </span>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="home-input"
                                    placeholder="Pick up location"
                                    name='source'
                                    onChange={handlesourceInputChange}
                                    value={formData.sourceAddress || ''}
                                    required
                                />
                            </div>
                        </div>
                    </Autocomplete>
                    <hr className='ms-4' />
                    <Autocomplete
                        onLoad={ref => (destinationRef.current = ref)}
                        onPlaceChanged={() => handlePlaceChange(destinationRef.current.getPlace(), 'destination')}
                        options={{
                            componentRestrictions: { country: 'in' },
                        }}
                    >
                        <div className='d-flex'>
                            <div>
                                <span>
                                    <i className="fa-solid fa-circle-dot mt-2 me-3 text-success"></i>
                                </span>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="home-input"
                                    placeholder="Drop location"
                                    name='destination'
                                    onChange={handledestinationInputChange}
                                    value={formData.destinationAddress || ''}
                                    required
                                />
                            </div>
                        </div>
                    </Autocomplete>
                </Form.Group>
                <hr className='ms-4' />
            </Col>
            <Col className='d-flex me-4'>
                <CiUser className='mt-1 me-3' />
                <input
                    placeholder='Contact Number'
                    className='home-input'
                    name='mobile_no'
                    type='tel'
                    value={formData.mobile_no}
                    onChange={handleChange}
                    required
                />
            </Col>
            <hr className='ms-4' />
            <Col>
                <div className='d-flex'>
                    <i className="far fa-calendar-alt mt-2 me-3"></i>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => handleTimeChange(date, 'time')}
                        placeholderText="Pick up date and time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals={60}
                        className='home-input custom-datepicker'
                        name='time'
                        minDate={new Date()}
                        minTime={startDate ? getMinTime(startDate) : null}
                        maxTime={startDate ? getMaxTime(startDate) : null}
                        required
                    />
                </div>
            </Col>
            <hr className='ms-4' />
            <Col>
                <div className='d-flex'>
                    <i className="far fa-calendar-alt mt-2 me-3"></i>
                    <DatePicker
                        selected={returnDate}
                        onChange={(date) => handleTimeChange(date, 'ret_time')}
                        placeholderText="Return date and time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect={false}
                        timeFormat="h:mm aa"
                        timeIntervals={60}
                        className='home-input custom-datepicker'
                        name='ret_time'
                        minDate={new Date()}
                        required
                    />
                </div>
            </Col>
            <hr className='ms-4' />
            {locationError && (<p className='text-danger'>{locationError}</p>)}
            {!locationError && error && (<p className='text-danger'>{error}</p>)}
            <SearchCabButton />
        </Form>
    )
}

export default RoundTrip