import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getHelmetContent, getPageContent } from '../api/Api';
import MyHelmet from '../Plugin/Helmet';
import Loader from '../Plugin/Loader';

function AboutUs() {
  const [getAboutData, setAboutData] = useState();
  const [getAboutPageData, setAboutPageData] = useState()

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getPageContent('1');
        if(getPageContent) {
          setAboutData(pageData.data);
        }
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getHelmetContent('About Us');
        if (pageData.data){
          setAboutPageData(pageData);
        }
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      {
        getAboutPageData && (
          <>
            <MyHelmet
              title={getAboutPageData.title}
              description={getAboutPageData.description}
              canonicalUrl={getAboutPageData.canonical}
            />
          </>
        )
      }
      <Container>
        <h1 className='heading-text text-center text-muted py-4'>ABOUT US</h1>
        {
          getAboutData ?  (<p dangerouslySetInnerHTML={{ __html: getAboutData }}></p>):(<Loader/>)
        }
      </Container>
    </div>
  )
}

export default AboutUs