import { Autocomplete } from '@react-google-maps/api';
import { endOfDay, format, setHours, setMinutes, startOfDay } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, FormSelect } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { CiUser } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRentalPackage, getRouteDetails } from '../api/Api';
import { SearchCabButton } from '../button/button';
import { setCarsData } from '../Redux_Toolkit/slices/CarsDataSlice';
import { setMobileNumb } from '../Redux_Toolkit/slices/LoginNumberSlice';
import { setRouteData } from '../Redux_Toolkit/slices/RouteDataSlice';

function DayRental() {
    const mobileNumb = useSelector((state) => {
        return state.mobile_no
    })
    const [formData, setFormData] = useState({ destination: '', name: '', source: '', type: 'local', mobile_no: mobileNumb?.data ?? '', time: '' });
    const [startDate, setStartDate] = useState(new Date());
    const sourceRef = useRef(null);
    const navigate = useNavigate();
    const [rental, setRental] = useState()
    const dispatch = useDispatch()
    const [error, setError] = useState()
    const [locationError, setLocationError] = useState('')

    const token = useSelector((state) => {
        return state.token.token
    })

    useEffect(() => {
        async function fetchData() {
            try {
                const rentalPackageData = await getRentalPackage('day-rental')
                setRental(rentalPackageData)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])

    const handlePlaceChange = async (place, name) => {
        if (place && place.geometry && place.geometry.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const locationString = `${lat},${lng}`;
            const fullAddress = place.formatted_address || '';
            setLocationError('');
            setFormData(prevValues => ({
                ...prevValues,
                [`${name}`]: locationString,
                [`${name}Address`]: fullAddress
            }));
        } else {
            setLocationError(`No valid location selected for ${name}. Please select a valid place.`);
        }
    };

    const handleInputChange = (e) => {
        setFormData((prevValues) => ({
            ...prevValues,
            sourceAddress: e.target.value
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'destination') {
            const selectedItem = rental.find(item => item.id === value);
            setFormData(prevValues => ({
                ...prevValues,
                destination: selectedItem ? selectedItem.id : '', // Set id
                name: selectedItem ? selectedItem.name : '' // Set name
            }));
        } else {
            // For other fields, use the normal update
            setFormData(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    const handleTimeChange = (date) => {
        const formattedDate = format(date, 'dd-MM-yyyy hh:mm a');
        setStartDate(date);
        setFormData(prevValues => ({
            ...prevValues,
            time: formattedDate
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const submittedForm = await getRouteDetails(formData);
            if (submittedForm.data && submittedForm.data.data) {
                const myCarData = submittedForm.data.data;
                dispatch(setMobileNumb(formData.mobile_no))
                dispatch(setCarsData(myCarData))
                dispatch(setRouteData(formData))
                navigate('/search');
            } else {
                setError(submittedForm.data.message)
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const getMinTime = (date) => {
        const currentDate = new Date();
        if (date.toDateString() === currentDate.toDateString()) {
            return setHours(
                setMinutes(new Date(), Math.ceil(currentDate.getMinutes() / 15) * 15),
                currentDate.getHours()
            );
        }
        return startOfDay(date);
    };

    const getMaxTime = (date) => {
        return endOfDay(date);
    };

    return (
        <Form onSubmit={handleSubmit} className='mt-4'>
            <Autocomplete
                onLoad={ref => (sourceRef.current = ref)}
                onPlaceChanged={() => handlePlaceChange(sourceRef.current.getPlace(), 'source')}
                options={{
                    componentRestrictions: { country: 'in' },
                    types: ['address'],
                }}
            >
                <div className='d-flex'>
                    <div>
                        <span>
                            <i className="fa-solid fa-circle-dot mt-2 me-3"></i>
                        </span>
                    </div>
                    <div>
                        <input
                            type="text"
                            className="home-input"
                            placeholder="Pick up location"
                            name='source'
                            onChange={handleInputChange}
                            value={formData.sourceAddress || ''}
                            required
                        />
                    </div>
                </div>
            </Autocomplete>
            <hr className='ms-4' />
            <Col className='mt-3'>
                <Form.Group className='d-flex' >
                    <span>
                        <i className="fa-solid fa-circle-dot mt-3 me-1 text-success"></i>
                    </span>
                    <FormSelect
                        className="home-input"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                        type='text'
                        placeholder='Drop location'
                        required
                    >
                        <option value="">Select Rental Package</option>
                        {
                            rental && (
                                rental.map((item) => (
                                    <option className="text-dark" key={item.id} value={item.id}>{item.name}</option>
                                ))
                            )
                        }
                    </FormSelect>
                </Form.Group>
            </Col>
            <hr className='ms-4' />
            <Col className='d-flex me-4'>
                <CiUser className='mt-1 me-3' />
                <input
                    placeholder='Contact Number'
                    className='home-input'
                    name='mobile_no'
                    type='tel'
                    value={formData.mobile_no}
                    onChange={handleChange}
                    required
                />
            </Col>
            <hr className='ms-4' />
            <Col>
                <div className='d-flex'>
                    <i className="far fa-calendar-alt mt-2 me-3"></i>
                    <DatePicker
                        selected={startDate}
                        onChange={handleTimeChange}
                        placeholderText="Pick up date and time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals={60}
                        className='home-input custom-datepicker'
                        name='time'
                        minDate={new Date()}
                        minTime={startDate ? getMinTime(startDate) : null}
                        maxTime={startDate ? getMaxTime(startDate) : null}
                        required
                    />
                </div>
            </Col>
            <hr className='ms-4' />
            {locationError && (<p className='text-danger'>{locationError}</p>)}
            {!locationError && error && (<p className='text-danger'>{error}</p>)}
            <SearchCabButton />
        </Form>
    );
};

export default DayRental;
