import { useEffect, useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { clearCarsData } from '../Redux_Toolkit/slices/CarsDataSlice';
import { clearMobile } from '../Redux_Toolkit/slices/LoginNumberSlice';
import { clearRouteData } from '../Redux_Toolkit/slices/RouteDataSlice';
import { clearToken } from '../Redux_Toolkit/slices/TokenSlice';
import { clearProfile } from '../Redux_Toolkit/slices/userDetailsSlice';
import { clearProfileUpdate } from '../Redux_Toolkit/slices/userUpdate';

function Header() {
    const navigate = useNavigate();
    const [isLaptop, setIsLaptop] = useState(window.innerWidth > 1024);
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(false);

    const token = useSelector((state) => {
        return state.token.token
    })

    const handleLogout = () => {
        dispatch(clearToken())
        dispatch(clearMobile())
        dispatch(clearProfile())
        dispatch(clearCarsData())
        dispatch(clearRouteData())
        dispatch(clearProfileUpdate())
        navigate('/login')
    }

    useEffect(() => {
        const handleResize = () => {
            setIsLaptop(window.innerWidth > 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <Navbar expand="lg" expanded={expanded} className="nav_2 bg-tertiary nav_1 shadow-lg px-2">
                <Navbar.Brand as={NavLink} to="/" className="text-white ms-lg-5">
                    <img src='https://www.ococabs.com/images/logo.png' width='200px' alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav ' onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="basic-navbar-nav text-white">
                    <Nav className="justify-content-end flex-grow-1 pe-3 my-custom-nav ms-md-3">
                        <Nav.Link as={NavLink} className='header-nav with-border' to="/" onClick={handleNavLinkClick}>Home</Nav.Link>
                        <Nav.Link as={NavLink} className='header-nav with-border' to="about-us" onClick={handleNavLinkClick}>About Us</Nav.Link>
                        <Nav.Link as={NavLink} className='header-nav with-border' to="blogs" onClick={handleNavLinkClick}>Blogs</Nav.Link>
                        <Nav.Link as={NavLink} className='header-nav with-border' to="contact-us" onClick={handleNavLinkClick}>Contact Us</Nav.Link>
                        {token == null ? (
                            <Nav.Link as={NavLink} className='header-nav with-border' to="login" onClick={handleNavLinkClick}><i className="fa-regular fa-user me-3"></i>Login</Nav.Link>
                        ) : (
                            <NavDropdown
                                title='My Account'
                                className='header-nav dropdown-centered'
                                data-toggle="collapse" >
                                <NavDropdown.Item as={NavLink} to="update-profile" onClick={handleNavLinkClick}><i className="fa-regular fa-user me-3" ></i>Update Profile</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="my-booking-list" onClick={handleNavLinkClick}><i className="fa-solid fa-car me-3"></i>My Bookings</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => {
                                    handleLogout();
                                    handleNavLinkClick();
                                }}><i className="fa-solid fa-arrow-right-from-bracket me-3"></i>Logout</NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {isLaptop && (
                            <button
                                className='button  text-white py-1 px-2 heading-text'
                                style={{ backgroundColor: '#cea427' }}
                                onClick={() => window.location.href = 'tel:+918421777463'}
                            >
                                Enquiry Call
                            </button>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Header;
