import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Footer() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  const currentYear = new Date().getFullYear();
  
  return (
    <Container fluid>
      <Row>
        <Col className='p-4 bg-dark text-white'>
          <h5 className='mb-2 text-lg-center'>SUPPORT NUMBER (24X7)</h5>
          <Col className='footer mb-2 gap-2'>
            <NavLink className='nav-link with-border' to='tel:+918421777463'>New Booking: +918421777463</NavLink>
            <NavLink className='nav-link with-border' to='tel:+918421777463'>Existing Booking: +918421777463</NavLink>
            <NavLink className='nav-link with-border' to='tel:+919730694559'>Attach Taxi/Cab: +919730694559</NavLink>
            <NavLink className='nav-link with-border' to='tel:+918421777463'>Customer Helpline: +918421777463</NavLink>
          </Col>
          <Col className='footer d-flex mb-2 gap-3'>
            <NavLink className='nav-link ' to='https://www.facebook.com/Akshada-Info-System-767861740053468/'><i className="fa-brands fa-square-facebook facebook" style={{ fontSize: '35px' }}></i></NavLink>
            <NavLink className='nav-link' to='https://twitter.com/AkshadaInfo'><i className="fa-brands fa-square-twitter twitter" style={{ fontSize: '35px' }}></i></NavLink>
            <NavLink className='nav-link' to='https://api.whatsapp.com/send?phone=918421777463'><i className="fa-brands fa-square-whatsapp whatsapp" style={{ fontSize: '35px' }}></i></NavLink>
            <NavLink className='nav-link' to='https://www.instagram.com/akshadainfosystem/'><i className="fa-brands fa-square-instagram instagram" style={{ fontSize: '35px' }}></i></NavLink>
            <NavLink className='nav-link' to='https://in.linkedin.com/company/akshada-info-system'><i className="fa-brands fa-linkedin linkedln" style={{ fontSize: '35px' }}></i></NavLink>
            <NavLink className='nav-link' to='https://www.youtube.com/@ococabs9550'><i className="fa-brands fa-square-youtube youtube" style={{ fontSize: '35px' }}></i></NavLink>
          </Col>
          <Col className='footer gap-4'>
            <NavLink className='nav-link with-border' to='terms-and-condition'>Terms & Conditions</NavLink>           
            <NavLink className='nav-link with-border' to='cancelation-policy'>Cancellation Policy</NavLink>
            <NavLink className='nav-link with-border' to='discount'>Discount & Offers</NavLink>
            <NavLink className='nav-link with-border' to='privacy-policy'>Privacy Policy</NavLink>
            <NavLink className='nav-link with-border' to='refund-policy'>Refund Policy</NavLink>
          </Col>
        </Col>
      </Row>
      <Row className='copyright text-center  p-4'>
        <Col>
          <span className='text-white'>&copy;{currentYear} All rights reserved</span>
        </Col>
      </Row>
    </Container>
  )
}
export default Footer;