import { useEffect, useState } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { FaEnvelope, FaPhoneAlt, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateProfile } from '../api/Api';
import { setError, setLoading, setProfileData } from '../Redux_Toolkit/slices/userDetailsSlice';
import { setProfileUpdateData } from '../Redux_Toolkit/slices/userUpdate';

function Update_Profile() {
    const data = useSelector((state) => {
        return state.userDetail?.data
    })
    const myProfileUpdate = useSelector((state) => {
        return state.profileUpdate.data
    })
    const nameAvailable = data?.name || myProfileUpdate?.name;
    const emailAvailable = data?.email || myProfileUpdate?.email;
    const [updateValues, setUpdateValues] = useState({ mobile_no: "", name: nameAvailable || '', email: emailAvailable || '', user_type: "user", token: "" });
    const [successUpdate, setSuccessUpdate] = useState('');
    const dispatch = useDispatch()
    
    const token = useSelector((state) => {
        return state.token.token
    })

    const mobileNumb = useSelector((state) => {
        return state.mobile_no
    })

    useEffect(() => {
        async function fetchData() {
            dispatch(setLoading());
            if (token) {
                try {
                    const getProfileData = await getProfile(token);
                    const data = JSON.parse(getProfileData);
                    if (data) {
                        dispatch(setProfileData(data));
                    }
                } catch (error) {
                    dispatch(setError('Error fetching state data:'));
                    console.error('Error fetching state data:', error);
                }
            }
        }
        fetchData();
    }, [token]);

    const handleUpdate = (e) => {
        const { name, value } = e.target;
        setUpdateValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        updateValues.token = token
        try {
            if (token) {
                const submittedForm = await updateProfile(updateValues);
                if (submittedForm.status === 200) {
                    dispatch(setProfileUpdateData({ name: updateValues.name, email: updateValues.email }));
                    setSuccessUpdate(submittedForm.data.message);
                }
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <>
            <Container fluid className='py-5 my-auto'>
                <h1 className='heading-text text-center text-muted my-4 fs-3'>USER DETAILS</h1>
                <Row className='justify-content-center mx-auto'>
                    <Col lg={4} md={8} sm={12} className='border pb-3 px-4'>
                        <p className='text-success text-center my-4 fs-5'>{successUpdate}</p>
                        <Form onSubmit={handleUpdateSubmit} >
                            <Form.Group controlId="name">
                                <InputGroup>
                                    <InputGroup.Text><FaUser /></InputGroup.Text>
                                    <Form.Control
                                        placeholder='Enter Your Name'
                                        type='text'
                                        name="name"
                                        required
                                        onChange={handleUpdate}
                                        value={updateValues.name}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="mobile_no" className='mt-4'>
                                <InputGroup>
                                    <InputGroup.Text><FaPhoneAlt /></InputGroup.Text>
                                    <Form.Control
                                        placeholder='Enter Your Number'
                                        type="tel"
                                        name="mobile_no"
                                        required
                                        onChange={handleUpdate}
                                        value={updateValues.mobile_no || data?.mobile_no || mobileNumb?.data }
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="email" className='mt-4'>
                                <InputGroup>
                                    <InputGroup.Text><FaEnvelope /></InputGroup.Text>
                                    <Form.Control
                                        placeholder='Enter Your Email'
                                        type='email'
                                        name="email"
                                        required
                                        onChange={handleUpdate}
                                        value={updateValues.email}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Col className="d-grid gap-2 mt-4">
                                <button size="md" type='submit' className='button text-white px-2'>
                                    Update
                                </button>
                            </Col>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Update_Profile;
