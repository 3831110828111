import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getPageContent } from '../api/Api';
import Loader from '../Plugin/Loader';

function Discount() {
  const [getDiscountData, setDiscountData] = useState();
  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await getPageContent('3');
        setDiscountData(pageData.data);
      } catch (error) {
        console.error('Error fetching state data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Container className='text-secondary py-4'>
        <h1 className='heading-text text-muted text-center py-4 fs-2'> DISCOUNT & OFFERS </h1>
        {
          getDiscountData ? (<p dangerouslySetInnerHTML={{ __html: getDiscountData }}></p>)
          :
           (<Loader/>)
        }
      </Container>
    </>
  )
}
export default Discount